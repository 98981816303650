import React from 'react';
import styled from 'styled-components';
import { AlertActionElem } from '../../common/alert-action';
import { TextElem } from '../../common/text';
import { COLOR_ENUM } from '../../theme/color';
import { WEBINAR_ITEM_DATA } from '../../data/webinar/constant';
import { Spacing } from '../../theme';

import { ReactComponent as DeleteIcon } from '../../asset/svg/common/close.svg';
import { CustomInput } from '../../common/field-text/elem';
import { ContentContainerElem } from '../../common/content-container';
import { WebcardItemCreateContainer } from '../webcard-item-create';
import { WEBCARD_ITEM_DATA_RAW } from '../../data/webcard/constant';
import { WebcardItemUpdateContainer } from '../webcard-item-update';
import { Skeleton } from './skeleton';

export const Component: React.FC<{
  data?: WEBINAR_ITEM_DATA;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  webinarId: string;
}> = ({
  data,
  isLoading,
  isSuccess,
  isError,
  errorMessage,

  webinarId,
}) => {
  return (
    <ContentContainerElem>
      <TextElem tid="WEBINAR.UPDATE.CARD.TITLE" type="semi-bold" size="main" />
      {isLoading && <Skeleton />}

      {isError && <AlertActionElem text={errorMessage} />}

      {isSuccess &&
        data &&
        data.webcard &&
        !!data.webcard.length &&
        data.webcard.map((item: WEBCARD_ITEM_DATA_RAW) => (
          <WebcardItemUpdateContainer data={item} />
        ))}
      <WebcardItemCreateContainer webinarId={webinarId} />
    </ContentContainerElem>
  );
};

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: auto 16px;
  align-items: center;
  gap: ${Spacing(3)};
  cursor: pointer;
`;

export const DeleteIconStyled = styled(DeleteIcon)`
  top: 15px;
  right: 16px;
  position: absolute;
  path {
    transition: all 0.2s;
  }
  :hover {
    path {
      stroke: ${({ theme }) => theme[COLOR_ENUM.ERROR]};
    }
  }
`;
