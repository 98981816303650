import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import { SkeletonFieldElem } from '../../common/skeleton-field';
import { SESSION_ITEM_DATA } from '../../data/session/constant';
import { BASE_URL } from '../../lib/http/constant';
import { SessionItemRoomLiveVideoContainer } from '../session-item-room-live-video';
import { VideoPlayerElem } from '../../common/video';
import { SessionItemRoomCardContainer } from '../session-item-room-card';

export const Component: React.FC<{
  data?: SESSION_ITEM_DATA;
  isLoading?: boolean;
  sessionStart: boolean;
  eventStarted: boolean;
  isSuccess?: boolean;
}> = ({ isLoading, isSuccess, data, sessionStart, eventStarted }) => {
  return (
    <Container>
      {isSuccess && (
        <>
          {data && sessionStart && (
            <SessionItemRoomLiveVideoContainer
              data={data}
              source={data?.webinar?.youtubeLink}
            />
          )}
          {data && eventStarted && !sessionStart && (
            <SessionItemRoomLiveVideoContainer
              data={data}
              source={data?.webinar?.youtubeLinkPreview}
              preview
            />
          )}
          {data && !eventStarted && !sessionStart && (
            <SessionItemRoomCardContainer data={data.webinar?.webcard} />
          )}
        </>
      )}

      {isLoading && !isSuccess && (
        <SkeletonField
          title={false}
          style={{ width: '100%', height: '100%' }}
        />
      )}
    </Container>
  );
};

const PlayerWrapper = styled.div`
  border-radius: 20px;
  video {
    border-radius: 20px;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%; /* или другая ширина, соответствующая вашему дизайну */
  height: 100%; /* Ограничение по высоте родительского контейнера */

  @media screen and (width>1060px) {
    min-height: 50vh;
  }
`;

const SkeletonField = styled(SkeletonFieldElem)`
  .field {
    height: 100%;
    width: 100%;
    min-height: 390px;
  }
`;
