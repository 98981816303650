import React from 'react';
import { RouteComponentProps } from 'react-router';
import { LayoutAppElem } from '../../common/layout-app';
import { PageElem } from '../../common/page';

import { MenuAppContainer } from '../../epic/menu-app';
import { LayoutElem } from '../../common/layout';

import { HeaderContainer } from '../../epic/header';
import icon from '../../asset/svg/header/moderation.svg';

import styled from 'styled-components';
import { ModerationContainer } from '../../epic/moderation';

export const Page: React.FC<RouteComponentProps> = () => {
  return (
    <PageElem>
      <LayoutAppElem>
        <MenuAppContainer />

        <LayoutElemStyled size="default">
          <HeaderContainer
            icon={icon}
            title="NAVIGATION.TAB_APP.MODERATION"
            size="default"
          />
          <ModerationContainer />
        </LayoutElemStyled>
      </LayoutAppElem>
    </PageElem>
  );
};

const LayoutElemStyled = styled(LayoutElem)`
  padding-right: 0;
  height: 100%;
`;
