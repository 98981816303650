import React from 'react';
import styled from 'styled-components';
import { AlertActionElem } from '../../common/alert-action';
import { TextElem } from '../../common/text';
import { COLOR_ENUM } from '../../theme/color';
import { WEBINAR_ITEM_DATA } from '../../data/webinar/constant';
import { FieldTextElem } from '../../common/field-text';
import { Spacing } from '../../theme';

import { ReactComponent as DeleteIcon } from '../../asset/svg/common/close.svg';
import { FormikValues } from 'formik';
import { ContentContainerElem } from '../../common/content-container';
import { SkeletonFieldElem } from '../../common/skeleton-field';
import { WEBINAR_UPDATE_FORM_VALUE_ENUM } from '../webinar-item-update-general';
import { WebinarItemUpdateBonusContainer } from '../webinar-item-update-bonus';
import { WebinarItemUpdateGuarantesContainer } from '../webinar-item-update-guarantes';
import { DoubleContainerElem } from '../../common/double-container';
import { SelectElem } from '../../common/select';
import { EXPENDITURE_VALUTE_OPTION_LIST } from '../../data/expenditure/constant';

export const Component: React.FC<{
  formik: FormikValues;
  data?: WEBINAR_ITEM_DATA;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  isFieldError: Function;
  getFieldError: Function;
  getFieldValue: Function;
  webinarId: string;
  setFieldValue: Function;
  setValue: Function;
}> = ({
  data,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  formik,
  setFieldValue,
  setValue,
  isFieldError,
  getFieldError,
  getFieldValue,
  webinarId,
}) => {
  return (
    <ContentContainerElem>
      <TextElem
        tid="WEBINAR.UPDATE.DESCRIPTION.TITLE"
        type="semi-bold"
        size="main"
      />

      {isLoading && <SkeletonFieldElem title />}

      {isError && <AlertActionElem text={errorMessage} />}

      {isSuccess && data && (
        <DoubleContainerElem>
          <FieldTextElem
            name={WEBINAR_UPDATE_FORM_VALUE_ENUM.PRICE}
            title="WEBINAR.UPDATE.DESCRIPTION.PRICE.TITLE"
            type="customNumber"
            step="0.00001"
            min="0"
            onKeyDown={(evt) => evt.key === ',' && evt.preventDefault()}
            onChange={formik.handleChange}
            onBlur={formik.handleSubmit}
            value={getFieldValue(WEBINAR_UPDATE_FORM_VALUE_ENUM.PRICE)}
            errorMessage={getFieldError(WEBINAR_UPDATE_FORM_VALUE_ENUM.PRICE)}
            error={isFieldError(WEBINAR_UPDATE_FORM_VALUE_ENUM.PRICE)}
          />

          <SelectElem
            name={WEBINAR_UPDATE_FORM_VALUE_ENUM.VALUTE}
            onChange={setFieldValue}
            options={EXPENDITURE_VALUTE_OPTION_LIST}
            title="EXPENDITURE.FORM.VALUTE.TITLE"
            errorMessage={getFieldError(WEBINAR_UPDATE_FORM_VALUE_ENUM.VALUTE)}
            error={isFieldError(WEBINAR_UPDATE_FORM_VALUE_ENUM.VALUTE)}
            value={setValue(
              EXPENDITURE_VALUTE_OPTION_LIST,
              WEBINAR_UPDATE_FORM_VALUE_ENUM.VALUTE,
            )}
          />
        </DoubleContainerElem>
      )}
      <WebinarItemUpdateBonusContainer webinarId={webinarId} />
      <WebinarItemUpdateGuarantesContainer webinarId={webinarId} />
    </ContentContainerElem>
  );
};

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: auto 16px;
  align-items: center;
  gap: ${Spacing(3)};
  cursor: pointer;
`;

export const DeleteIconStyled = styled(DeleteIcon)`
  top: 15px;
  right: 16px;
  position: absolute;
  path {
    transition: all 0.2s;
  }
  :hover {
    path {
      stroke: ${({ theme }) => theme[COLOR_ENUM.ERROR]};
    }
  }
`;
