import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { GridElem } from '../../common/grid';
import { TextElem } from '../../common/text';
import { WEBCARD_ITEM_DATA } from '../../data/webcard/constant';
import { WEBGIFT_ITEM_DATA_RAW } from '../../data/webgift/constant';
import { COLOR_ENUM } from '../../theme/color';
import { FlexElem } from '../../common/flex';
import { Spacing } from '../../theme';
import { ContentContainerElem } from '../../common/content-container';

export const Component: React.FC<{
  data?: WEBCARD_ITEM_DATA[];
}> = ({ data }) => {
  return (
    <Container>
      {data?.map((item: WEBGIFT_ITEM_DATA_RAW) => (
        <GiftCard spacing={4}>
          <ImageContainer>
            <Image src={item.photo?.url} />
          </ImageContainer>
          <GridElem
            style={{
              justifyContent: 'start',
              textAlign: 'start',
            }}
            spacing={1}
          >
            <TextElem type="semi-bold" size="main">
              {item.name}
            </TextElem>
            <TextElem type="medium" size="mobileDefault" color="textPrimary">
              {item.description}
            </TextElem>
          </GridElem>
        </GiftCard>
      ))}
    </Container>
  );
};

const Container = styled(ContentContainerElem)`
  position: relative;
  width: 100%; /* или другая ширина, соответствующая вашему дизайну */
  height: 100%; /* Ограничение по высоте родительского контейнера */
  grid-template-columns: 1fr 1fr;
  border-radius: 20px;
  height: auto;
  row-gap: 56px;
  column-gap: 32px;
  padding: 40px;
  @media screen and (width<1000px) {
    padding: 24px;
    row-gap: 24px;
    column-gap: 32px;
  }
  @media screen and (width<800px) {
    grid-template-columns: 1fr;
    row-gap: 24px;
  }
  @media screen and (width<600px) {
    padding: 24px 12px;
    row-gap: 16px;
  }
  span {
    line-height: 150% !important;
  }
`;

const GiftCard = styled(GridElem)`
  grid-template-columns: 95px auto;
  /* grid-template-rows: 85px; */

  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
  border-radius: 12px;
  height: 100%;
  span {
    text-align: start;
  }
  @media screen and (width<600px) {
    grid-template-columns: 72px auto;
  }
`;

const ImageContainer = styled(FlexElem)`
  justify-content: center;
  align-items: center;
  width: 95px;
  height: 95px;
  overflow: hidden;

  border-radius: 12px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  @media screen and (width<600px) {
    width: 72px;
    height: 72px;
    border-radius: 10px;
  }
`;

const Image = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
`;
