import React, { useEffect, useState } from 'react';

import { useTimer } from 'react-timer-hook';

import { SESSION_ITEM_DATA } from '../../data/session/constant';
import { TextElem } from '../../common/text';
import { GridElem } from '../../common/grid';
import { AlertActionElem } from '../../common/alert-action';
import styled from 'styled-components';
import { Spacing } from '../../theme';
import { ButtonElem } from '../../common/button';
import { COLOR_ENUM } from '../../theme/color';
import { FlexElem } from '../../common/flex';
import { ReactComponent as ClockIcon } from '../../asset/svg/session/clock.svg';
import { DoubleContainerElem } from '../../common/double-container';
import { ModalElem } from '../../common/modal';
import { SessionItemJoinContainer } from '../session-item-join';
import { SessionItemRoomReservationContainer } from '../session-item-room-reservation';
import { Skeleton } from './skeleton';
import { StepperElem } from '../../common/stepper';
import { useSelector } from '../../lib/store';
import { THEME_MODULE_NAME } from '../../data/theme';
import { UTMObject } from '../../data/viewer/constant';
import { AvatarElem } from '../../common/avatar';
import { RESERVATION_SOURCE } from '../../data/analytic/constant';

export const Component: React.FC<{
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  data?: SESSION_ITEM_DATA;
  sessionId: string;
  query: UTMObject;
  date: string;
}> = ({
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  data,
  sessionId,
  query,
  date,
}) => {
  const { seconds, minutes, hours, days, isRunning, restart } = useTimer({
    expiryTimestamp: new Date('1970-01-01T00:00:00.0000'),
  });
  useEffect(() => {
    if (data?.serverDate) {
      restart(new Date(data.serverDate));
    }
  }, [data?.serverDate]);

  const second = seconds > 9 ? seconds.toString() : '0' + seconds.toString();
  const minute = minutes > 9 ? minutes.toString() : '0' + minutes.toString();
  const hour =
    hours + 24 * days > 9
      ? (hours + 24 * days).toString()
      : '0' + hours.toString();
  const day = days > 9 ? days.toString() : '0' + days.toString();
  const [topicVisible, setTopicVisible] = useState(false);
  const modalOpen = (e: any) => {
    setTopicVisible(true);
  };

  const modalClose = (e: any) => {
    if (e) {
      e.stopPropagation();
    }
    setTopicVisible(false);
  };

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const { theme } = useSelector((s: any) => ({
    theme: s[THEME_MODULE_NAME],
  }));
  return (
    <Container spacing={width < 500 ? 8 : 10}>
      {data?.webinar?.logo?.url && <Logo src={data?.webinar?.logo?.url} />}

      {isLoading && <Skeleton />}
      {isSuccess && data && (
        <GridElem spacing={5}>
          <Center spacing={6} style={{ maxWidth: '400px', margin: 'auto' }}>
            <MainContainer spacing={8}>
              <MediaContent>
                <SpeakerInfo>
                  <SpeakerImg
                    size={40}
                    userAvatar={data.webinar?.speakerImg?.url}
                  />
                  <SpeakerTextInfo spacing={1}>
                    <SpeakerText
                      tid="SESSION.ITEM.SPEAKER"
                      size="mobileDefault"
                      color="textPrimary"
                    />
                    <SpeakerName
                      tid="SESSION.ITEM.SPEAKER_NAME"
                      size="main"
                      tvalue={{
                        value: data.webinar.speakerName,
                      }}
                    />
                  </SpeakerTextInfo>
                </SpeakerInfo>
                <ButtonElem
                  tid="SESSION.ITEM.BUTTON"
                  fill="outline"
                  onClick={modalOpen}
                />
              </MediaContent>
              <ModalElem
                open={topicVisible}
                titleTid="SESSION.ITEM.TOPIC"
                onClose={modalClose}
                closeIcon
              >
                <StepperElem
                  stepArray={data.webinar.topics.map((item) => {
                    return {
                      topic: item,
                    };
                  })}
                />
              </ModalElem>
            </MainContainer>
            {data.sessionIsOver && (
              <GridElem spacing={6}>
                <p style={{ textAlign: 'center' }}>
                  <TextElem
                    type="semi-bold"
                    size="medium"
                    tid="SESSION.ITEM.END"
                  />
                  <TextElem
                    type="semi-bold"
                    size="medium"
                    tid="SESSION.ITEM.THANK"
                    color="active"
                  />
                </p>
                <SessionName
                  type="bold"
                  size={width < 500 ? 'medium' : 'heading'}
                  tid="SESSION.ITEM.NAME"
                  lineHeight
                  tvalue={{ value: data.name }}
                />
              </GridElem>
            )}
          </Center>
          {data.sessionIsOver ? (
            <SessionItemRoomReservationContainer
              sessionId={sessionId}
              webinarData={data.webinar}
              source={RESERVATION_SOURCE.INACTIVE_SESSION}
              date={date}
              defaultCountry={data.countryCode}
            />
          ) : (
            <Center>
              <GridElem spacing={6}>
                <SessionName
                  type="bold"
                  size={width < 500 ? 'medium' : 'heading'}
                  tid="SESSION.ITEM.NAME"
                  lineHeight
                  tvalue={{ value: data.name }}
                />
                <Center spacing={3}>
                  <DayContainer spacing={1}>
                    <ClockIconStyled />
                    <TextElem
                      lineHeight
                      size={width < 500 ? 'mobileDefault' : 'default'}
                      type="semi-bold"
                      color="textSecondary"
                    >
                      {data.day}
                    </TextElem>

                    <TextElem
                      lineHeight
                      size={width < 500 ? 'mobileDefault' : 'default'}
                      color="textPrimary"
                    >
                      at
                    </TextElem>
                    <TextElem
                      lineHeight
                      size={width < 500 ? 'mobileDefault' : 'default'}
                      type="semi-bold"
                      color="textSecondary"
                    >
                      {data.time}
                    </TextElem>
                    <TextElem
                      lineHeight
                      size={width < 500 ? 'mobileDefault' : 'default'}
                      color="textPrimary"
                    >
                      by {data.timeZone}
                    </TextElem>
                  </DayContainer>
                  <Center>
                    {!data.sessionStarted || (!isRunning && seconds !== 0) ? (
                      <TextElem
                        type="bold"
                        size="title"
                        color="textFourth"
                        lineHeight
                      >
                        <TimeContainer>
                          <Center spacing={2}>
                            <FlexElem spacing={1}>
                              <TimeNumberContainer>
                                {hour[0]}
                              </TimeNumberContainer>
                              <TimeNumberContainer>
                                {hour[1]}
                              </TimeNumberContainer>
                              :
                            </FlexElem>
                            <TextElem
                              tid="год."
                              size={width < 500 ? 'small' : 'default'}
                              color="textThird"
                            />
                          </Center>
                          <Center spacing={2}>
                            <FlexElem spacing={1}>
                              <TimeNumberContainer>
                                {minute[0]}
                              </TimeNumberContainer>
                              <TimeNumberContainer>
                                {minute[1]}
                              </TimeNumberContainer>
                              :
                            </FlexElem>
                            <TextElem
                              tid="хв."
                              size={width < 500 ? 'small' : 'default'}
                              color="textThird"
                            />
                          </Center>
                          <Center spacing={2}>
                            <FlexElem spacing={1}>
                              <TimeNumberContainer>
                                {second[0]}
                              </TimeNumberContainer>
                              <TimeNumberContainer>
                                {second[1]}
                              </TimeNumberContainer>
                            </FlexElem>
                            <TextElem
                              tid="сек."
                              size={width < 500 ? 'small' : 'default'}
                              color="textThird"
                            />
                          </Center>
                        </TimeContainer>
                      </TextElem>
                    ) : (
                      <FlexElem
                        spacing={1}
                        style={{ justifyContent: 'center' }}
                      >
                        <TextElem
                          tid="SESSION.ITEM.STARTED"
                          size="main"
                          type="semi-bold"
                        />
                        <TextColor
                          tid="SESSION.ITEM.JOIN"
                          size="main"
                          type="semi-bold"
                        />
                      </FlexElem>
                    )}
                  </Center>
                </Center>
              </GridElem>

              <MainContainer spacing={8} style={{ maxWidth: '290px' }}>
                <GridElem spacing={4}>
                  <SessionItemJoinContainer
                    sessionId={data.id}
                    query={query}
                    defaultCountry={data.countryCode}
                  />

                  {data.sessionStarted && (
                    <DoubleContainerElem
                      style={{ gridTemplateColumns: '1fr 1fr' }}
                    >
                      <ContentCard spacing={2}>
                        <FlexElem spacing={1}>
                          <TextElem
                            tid="Дивляться"
                            size={width < 500 ? 'small' : 'mobileDefault'}
                            color="textPrimary"
                          />
                          <Dot />
                        </FlexElem>
                        <TextElem
                          tid={`${data.userOnline}`}
                          size={width < 500 ? 'small' : 'mobileDefault'}
                          type="semi-bold"
                          color="textSecondary"
                        />
                      </ContentCard>
                      <ContentCard spacing={2}>
                        <TextElem
                          tid="Вільних місць"
                          size={width < 500 ? 'small' : 'mobileDefault'}
                          color="textPrimary"
                        />
                        <FlexElem spacing={0}>
                          <TextElem
                            tid={`${data.availableSeats}`}
                            size={width < 500 ? 'small' : 'mobileDefault'}
                            type="semi-bold"
                            color="textSecondary"
                          />
                          <TextElem
                            tid={`/${data.onlineUserMax}`}
                            size={width < 500 ? 'small' : 'mobileDefault'}
                            type="semi-bold"
                            color="textThird"
                          />
                        </FlexElem>
                      </ContentCard>
                    </DoubleContainerElem>
                  )}
                </GridElem>
              </MainContainer>
              {isError && <AlertActionElem text={errorMessage} />}
            </Center>
          )}
        </GridElem>
      )}
    </Container>
  );
};

const MainContainer = styled(GridElem)`
  @media screen and (width<500px) {
    gap: ${Spacing(7)};
  }
`;

const ClockIconStyled = styled(ClockIcon)`
  path {
    /* stroke: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]}; */
    fill: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
  }
`;

const TextColor = styled(TextElem)`
  background: ${({ theme }) => theme[COLOR_ENUM.BUTTON_PRIMARY]};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Dot = styled.div`
  height: 4px;
  width: 4px;
  border-radius: 100%;

  background: ${({ theme }) => theme[COLOR_ENUM.SUCCESS]};
`;

export const DayContainer = styled(FlexElem)`
  justify-content: center;
`;
const Container = styled(GridElem)`
  /* justify-content: center; */
`;

const Logo = styled.img`
  margin: auto;
  width: auto;
  height: auto;
`;

export const SpeakerImg = styled(AvatarElem)`
  @media screen and (width<500px) {
    img {
      height: 32px !important;
      width: 32px !important;
    }
  }
`;

export const MediaContent = styled(FlexElem)`
  width: 100%;
  gap: ${Spacing(3)};
  justify-content: space-between;
  button {
    width: fit-content;
  }
  @media screen and (width<500px) {
    flex-wrap: wrap;
    button {
      /* min-width: 140px; */
      padding: 0 ${Spacing(3)};
      width: minmax(auto, 100%);
      span {
        font-size: 12px;
      }
    }
  }
`;

export const SpeakerInfo = styled.div`
  display: flex;
  gap: ${Spacing(3)};
  align-items: center;
`;

export const SpeakerText = styled(TextElem)`
  height: 13px;
  @media screen and (width<500px) {
    height: 11px;
    font-size: 11px;
  }
`;

export const SpeakerName = styled(TextElem)`
  height: 16px;
  @media screen and (width<500px) {
    height: 13px;
    font-size: 13px;
  }
`;

export const SpeakerTextInfo = styled(GridElem)``;

export const Center = styled(GridElem)`
  justify-items: center;
`;

const SessionName = styled(TextElem)`
  text-align: center;
  line-height: 130%;
`;

const TimeNumberContainer = styled.div`
  width: 35px;
  height: 35px;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]};
  border-radius: 7px;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  line-height: 100%;
  padding: 10px 10px;
`;

const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${Spacing(1)};
  margin: auto;
`;

const ContentCard = styled(GridElem)`
  padding: ${Spacing(3)};
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
  border-radius: 10px;
`;
