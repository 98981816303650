import React from 'react';

import { Component } from './component';
import {
  EVENT_ITEM_DATA_RAW,
  QUESTION_TYPE,
  REACTION_RAW_TYPE,
  RESERVATION_CREATED_TYPE,
  SURVEY_TYPE,
} from '../../data/event/constant';
import { WEBINAR_ITEM_DATA_RAW } from '../../data/webinar/constant';

export const Container: React.FC<{
  banner?: EVENT_ITEM_DATA_RAW;
  button?: EVENT_ITEM_DATA_RAW;
  reaction?: REACTION_RAW_TYPE;
  reservationCreated?: RESERVATION_CREATED_TYPE;
  question?: QUESTION_TYPE;
  sendReaction: (emoji: string) => void;

  sessionId: string;
  webinarData?: WEBINAR_ITEM_DATA_RAW;
  reservation: EVENT_ITEM_DATA_RAW;
  reservationButtonClick?: Function;
  survey: SURVEY_TYPE;
  sendAnswer: (answer: number) => void;
  reactionActive: boolean;
  defaultCountry: string;
}> = ({
  banner,
  button,
  sendReaction,
  reaction,
  reservationCreated,
  question,
  sessionId,
  webinarData,
  reservation,
  reservationButtonClick,
  survey,
  sendAnswer,
  reactionActive,
  defaultCountry,
}) => {
  return (
    <Component
      componentsData={{
        banner,
        button,
        reaction,
        question,
      }}
      sessionId={sessionId}
      webinarData={webinarData}
      reservationButtonClick={reservationButtonClick}
      reservation={reservation}
      reservationCreated={reservationCreated}
      sendReaction={sendReaction}
      survey={survey}
      sendAnswer={sendAnswer}
      defaultCountry={defaultCountry}
      reactionActive={reactionActive}
    />
  );
};
