import React from 'react';

import { FormikValues } from 'formik';

import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { i18n } from '../../lib/lang';
import { FORM_VALUE_ENUM } from './constant';
import { getProjectList } from '../../data/project/action';
import { convertProjectListToSelect } from '../../data/project/convert';
import { SelectPreloadContainer } from '../select-preload';
import { TextElem } from '../../common/text';
import { WEBINAR_ITEM_DATA } from '../../data/webinar/constant';
import { ContentContainerElem } from '../../common/content-container';
import { DoubleContainerElem } from '../../common/double-container';
import { Skeleton } from './skeleton';
import { FieldTextAreaElem } from '../../common/field-text-area';
import styled from 'styled-components';
import { ToggleElem } from '../../common/toggle';
import { COLOR_ENUM } from '../../theme/color';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isLoadingPreFetch?: boolean;
  isSuccessPreFetch?: boolean;
  isError?: boolean;
  errorMessage?: string;
  setFieldValue: Function;
  setValue: Function;
  data?: WEBINAR_ITEM_DATA;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isSuccess,
  isLoadingPreFetch,
  isSuccessPreFetch,
  isError,
  errorMessage,
  setFieldValue,
}) => {
  return (
    <div>
      {isLoading && <LoaderElem />}
      {isLoadingPreFetch && <Skeleton />}
      {isSuccessPreFetch && (
        <ContentContainerElem>
          <GridElem spacing={5}>
            <TextElem tid="WEBINAR.UPDATE.TITLE" type="semi-bold" size="main" />

            <FieldTextElem
              name={FORM_VALUE_ENUM.NAME}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title="WEBINAR.UPDATE.FORM.NAME"
              value={getFieldValue(FORM_VALUE_ENUM.NAME)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.NAME)}
              error={isFieldError(FORM_VALUE_ENUM.NAME)}
            />
            <DoubleContainerElem>
              <SelectPreloadContainer
                name={FORM_VALUE_ENUM.PROJECT}
                action={getProjectList}
                convert={convertProjectListToSelect}
                formik={formik}
                title="WEBINAR.UPDATE.FORM.PROJECT"
                onChange={setFieldValue}
                errorMessage={getFieldError(FORM_VALUE_ENUM.PROJECT)}
                error={isFieldError(FORM_VALUE_ENUM.PROJECT)}
                value={getFieldValue(FORM_VALUE_ENUM.PROJECT)}
              />
              <FieldTextElem
                name={FORM_VALUE_ENUM.SPEAKER_NAME}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="WEBINAR.UPDATE.FORM.SPEAKER_NAME"
                value={getFieldValue(FORM_VALUE_ENUM.SPEAKER_NAME)}
                errorMessage={getFieldError(FORM_VALUE_ENUM.SPEAKER_NAME)}
                error={isFieldError(FORM_VALUE_ENUM.SPEAKER_NAME)}
              />
            </DoubleContainerElem>
            <FieldTextAreaElem
              name={FORM_VALUE_ENUM.SMS_TEXT}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title="PROJECT.FORM.SMS_TEXT"
              value={getFieldValue(FORM_VALUE_ENUM.SMS_TEXT)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.SMS_TEXT)}
              error={isFieldError(FORM_VALUE_ENUM.SMS_TEXT)}
            />
            <DoubleContainerElem>
              <ToggleContainer>
                <Toggle
                  tid="WEBINAR.UPDATE.FORM.ANIMATION_ACTIVE"
                  checked={getFieldValue(FORM_VALUE_ENUM.ANIMATION_ACTIVE)}
                  name={FORM_VALUE_ENUM.ANIMATION_ACTIVE}
                  handleClick={formik.handleChange}
                />
              </ToggleContainer>

              <ButtonElem
                type="submit"
                tid="WEBINAR.UPDATE.BUTTON.UPDATE"
                fill="solid"
                color="success"
                disabled={isSubmitDisabled()}
                onClick={formik.handleSubmit}
              />
            </DoubleContainerElem>

            {isError && <AlertActionElem text={i18n.t(`${errorMessage}`)} />}
            {isSuccess && <AlertActionElem type="success" tid="Success" />}
          </GridElem>
        </ContentContainerElem>
      )}
    </div>
  );
};

const Toggle = styled(ToggleElem)`
  height: 20px !important;
  width: 33px !important;
  --handle-width: 15px;
  --handle-height: 15px;
  --handle-spacing: 1.8px;
`;

const ToggleContainer = styled.div`
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};
  border-radius: 10px;
  padding: 0 16px;

  height: 46px;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  div > div {
    height: 19px;
  }
`;
