import {
  EVENT_ITEM_DATA_RAW,
  EVENT_ITEM_DATA,
  EVENT_ITEM_LIST_DATA,
  EVENT_IMG_DATA,
  REACTION_TYPE,
  REACTION_RAW_TYPE,
  SURVEY_RAW_TYPE,
  SURVEY_TYPE,
} from './constant';

const convertReaction = (reaction: REACTION_RAW_TYPE): REACTION_TYPE[] => {
  return reaction && Object.values(reaction);
};

export const convertSurvey = (
  survey: SURVEY_RAW_TYPE,
  clientId: string,
): SURVEY_TYPE => {
  const totalQuantity = survey.options.reduce(
    (sum, item) => sum + item.quantity,
    0,
  );

  let percentageSum = 0;
  const updatedOptions = survey.options.map((item, index, arr) => {
    if (index === arr.length - 1) {
      return { ...item, percentage: 100 - percentageSum };
    }

    const percentage = totalQuantity
      ? Math.round((item.quantity / totalQuantity) * 100)
      : 0;
    percentageSum += percentage;

    return { ...item, percentage };
  });

  return {
    ...survey,
    answer: survey.answers?.[clientId],
    options: updatedOptions,
  };
};

export const convertEventMessageToSelect = (arr: EVENT_ITEM_DATA_RAW[]) => {
  const newArray: any = [];
  if (arr) {
    arr?.map((item: EVENT_ITEM_DATA_RAW) => {
      newArray.push({
        value: item.id,
        content: item.message,
        title: `${item?.person?.name}, ${item.time}`,
        label: item.message,
      });
    });
  }
  return newArray;
};

export const convertEvent = (event: EVENT_ITEM_DATA_RAW): EVENT_ITEM_DATA => {
  const reactionArray: REACTION_TYPE[] = convertReaction(event.reaction);

  return {
    ...event,
    reaction: reactionArray,
    TypeIcon: EVENT_IMG_DATA[event.type],
    emojiList: reactionArray
      ?.map((item) => item.emoji)
      .toString()
      .replaceAll(',', '  '),
    reactionCounter: reactionArray?.length,
  };
};

export const convertEventList = (
  personList: EVENT_ITEM_DATA_RAW[],
): EVENT_ITEM_LIST_DATA => {
  return {
    list: personList?.map((person: EVENT_ITEM_DATA_RAW) => {
      return convertEvent(person);
    }),
    isEmpty: !personList?.length,
  };
};
