import { FormikValues } from 'formik';
import { FieldTextElem } from '../../../common/field-text';
import { GridElem } from '../../../common/grid';
import { FORM_VALUE_ENUM } from '../constant';

export const Form: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  getFieldValue: Function;
  defaultCountry: string;
}> = ({
  formik,
  getFieldValue,
  getFieldError,
  isFieldError,
  defaultCountry,
}) => {
  return (
    <GridElem spacing={4} style={{ textAlign: 'start' }}>
      <FieldTextElem
        name={FORM_VALUE_ENUM.NAME}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        title="SESSION.VIEWER.RESERVATION.FORM.NAME"
        value={getFieldValue(FORM_VALUE_ENUM.NAME)}
        errorMessage={getFieldError(FORM_VALUE_ENUM.NAME)}
        error={isFieldError(FORM_VALUE_ENUM.NAME)}
      />

      <FieldTextElem
        name={FORM_VALUE_ENUM.PHONE}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        title="SESSION.VIEWER.RESERVATION.FORM.PHONE"
        value={getFieldValue(FORM_VALUE_ENUM.PHONE)}
        errorMessage={getFieldError(FORM_VALUE_ENUM.PHONE)}
        error={isFieldError(FORM_VALUE_ENUM.PHONE)}
        type="phone"
        defaultCountry={defaultCountry}
      />
    </GridElem>
  );
};
