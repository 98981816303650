export const MODULE_NAME = 'MODERATION_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const TAB_LIST = [
  {
    tid: 'All',
    value: 'ALL',
  },
  {
    tid: 'Moderation',
    value: 'MODERATION',
  },
];

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
