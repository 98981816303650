import React, {
  ChangeEvent,
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ContentContainerElem } from '../../common/content-container';
import styled, { css } from 'styled-components';
import { GridElem } from '../../common/grid';
import { ButtonElem } from '../../common/button';
import {
  MESSAGE_ITEM_DATA,
  REPLAY_MESSAGE_DATA,
} from '../../data/message/constant';
import sendMessage from '../../asset/svg/button/send-message.svg';
import { ReactComponent as ChatBlockIcon } from '../../asset/svg/session/room/chat/block.svg';
import sendMessageError from '../../asset/svg/button/send-message-error.svg';
import { ReactComponent as OffensiveWordIcon } from '../../asset/svg/session/room/chat/offensive-word.svg';

import {
  CloseIconStyled,
  MessageItemElem,
  scrollToMessage,
} from '../../data/message/frame/message-item-list.elem';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { COLOR_ENUM } from '../../theme/color';
import { Spacing } from '../../theme';
import { FlexElem } from '../../common/flex';
import { TextElem } from '../../common/text';
import { ModalElem } from '../../common/modal';
import { AvatarElem } from '../../common/avatar';
import { SkeletonFieldElem } from '../../common/skeleton-field';
import { ReactComponent as ReplyIcon } from '../../asset/svg/session/room/chat/reply.svg';
import { FieldTextAreaElem } from '../../common/field-text-area';
import { MessageItemModerationElem } from '../../data/message/frame/message-item-moderation-list.elem';
import { ChatSamplePhrases } from './frame/ChatSamplePhrases';

export const Component: React.FC<{
  chatMessage: MESSAGE_ITEM_DATA[];
  isSubmitDisabled: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  error: string;
  changeMessage: Function;
  pressEnter: KeyboardEventHandler<HTMLInputElement>;
  newMessage: string;
  sendMessageWrapper: Function;
  eventStarted: boolean;
  moderMessage?: string;
  reply: REPLAY_MESSAGE_DATA | null;
  setReply: Function;
  toggleMessageSelection: Function;
  selectedMessages: { messageIds: string[]; viewerIds: string[] };
  preModerationActive: boolean;
  setNewMessage: Function;
}> = ({
  chatMessage,
  isSubmitDisabled,
  error,
  changeMessage,
  pressEnter,
  newMessage,
  sendMessageWrapper,
  eventStarted,
  moderMessage,
  isLoading,
  isSuccess,
  reply,
  setReply,
  toggleMessageSelection,
  selectedMessages,
  preModerationActive,
  setNewMessage,
}) => {
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chatMessage, chatContainerRef]);

  const cancelReply = () => {
    setReply(null);
  };
  return (
    <Container>
      {isSuccess && (
        <>
          {eventStarted ? (
            <ChatContainer ref={chatContainerRef}>
              <>
                {chatMessage.map((message: MESSAGE_ITEM_DATA) => (
                  <MessageItemModerationElem
                    data={message}
                    setReply={setReply}
                    elemId={message.id}
                    onSelect={toggleMessageSelection}
                    selectList={selectedMessages}
                    preModerationActive={preModerationActive}
                  />
                ))}
              </>
            </ChatContainer>
          ) : (
            // <FieldContainer>
            <BlockContainer>
              <BlockContent spacing={3}>
                <ChatBlockIconStyled />
                <TextElem tid="SESSION.ROOM.CHAT.BLOCK.CONTAINER" />
              </BlockContent>
              <ModerMessageContainer>
                <AvatarElem size={20} className="avatar" />
                <GridElem spacing={1}>
                  <TextElem
                    type="medium"
                    color="error"
                    size="mobileDefault"
                    tid="SESSION.ROOM.CHAT.BLOCK.MODER"
                  />
                  <TextElem
                    color="textFourth"
                    style={{ wordBreak: 'break-all' }}
                    size="mobileDefault"
                  >
                    {moderMessage}
                  </TextElem>
                </GridElem>
              </ModerMessageContainer>
            </BlockContainer>
            // {/* </FieldContainer> */}
          )}

          <FieldContainer spacing={1}>
            <div style={{ width: '100%' }}>
              {reply && (
                <ReplyContainer onClick={() => scrollToMessage(reply.id)}>
                  <GridElem spacing={1}>
                    <FlexElem spacing={4}>
                      <FlexElem spacing={1}>
                        <TextElem
                          type="medium"
                          color="textPrimary"
                          size="mobileDefault"
                        >
                          {reply.name}
                        </TextElem>
                        <ReplyIcon />
                      </FlexElem>
                      <CloseIconStyled onClick={cancelReply} />
                    </FlexElem>

                    <TextElem
                      color="textFourth"
                      style={{ wordBreak: 'break-all' }}
                      size="mobileDefault"
                    >
                      {reply.content}
                    </TextElem>
                  </GridElem>
                </ReplyContainer>
              )}
              <CustomFieldContainer>
                <FieldText
                  onChange={changeMessage}
                  value={newMessage}
                  onKeyDown={pressEnter}
                  containsOffensiveWords={!!error}
                  disabled={!eventStarted}
                  reply={!!reply}
                  rows={1}
                  autoHeight={true}
                  placeholder={
                    eventStarted ? '' : 'SESSION.ROOM.CHAT.BLOCK.PLACEHOLDER'
                  }
                />
                {eventStarted && (
                  <ButtonStyled
                    onClick={sendMessageWrapper}
                    disabled={isSubmitDisabled()}
                    fill="icon"
                    iconRight={error ? sendMessageError : sendMessage}
                  />
                )}
              </CustomFieldContainer>
            </div>
            <ChatSamplePhrases onInsertText={setNewMessage} />

            {error && (
              <OffensiveWordsAlert spacing={3}>
                <OffensiveWordIcon style={{ minWidth: '18px' }} />
                <TextElem
                  color="error"
                  lineHeight
                  tid={error}
                  size="semiSmall"
                />
              </OffensiveWordsAlert>
            )}
          </FieldContainer>
        </>
      )}
      {isLoading && <SkeletonFieldElem title={false} />}
    </Container>
  );
};

const ChatBlockIconStyled = styled(ChatBlockIcon)`
  path {
    fill: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
  }
`;

const FieldContainer = styled(GridElem)`
  padding: 0 20px;
`;

const ReplyContainer = styled.div`
  border-radius: 10px 10px 0 0;
  background: ${({ theme }) => theme[COLOR_ENUM.BORDER_DEFAULT]};
  padding: ${Spacing(3)};
`;

const ModerMessageContainer = styled(GridElem)`
  grid-template-columns: 20px auto;
  gap: ${Spacing(2)};
  @media screen and (width<420px) {
    span {
      font-size: 11px !important;
    }
    .avatar {
      height: 16px !important;
      width: 16px !important;
    }
  }
`;

const BlockContainer = styled(FlexElem)`
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding-top: ${Spacing(5)};
  padding: 0 20px;
  padding-top: 20px;
`;

const BlockContent = styled(GridElem)`
  border-radius: ${Spacing(4)};
  padding: ${Spacing(6)} ${Spacing(5)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  text-align: center;
  justify-items: center;
`;

const OffensiveWordsAlert = styled(FlexElem)`
  padding: ${Spacing(3)};
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme[COLOR_ENUM.ERROR_BACKGROUND]};
  border-radius: 10px;
`;

const FieldText = styled(FieldTextAreaElem)<{
  containsOffensiveWords: boolean;
  reply: boolean;
}>`
  textarea {
    padding: 10px 16px;

    padding-right: 50px;
    resize: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  ${({ containsOffensiveWords, theme, reply }) => {
    if (reply) {
      return css`
        border-radius: 0 0 10px 10px;
        border-top: none;
      `;
    }
    if (containsOffensiveWords) {
      return css`
        border-color: ${theme[COLOR_ENUM.ERROR]};
        &:focus-within {
          border-color: ${theme[COLOR_ENUM.ERROR]} !important;
        }
        &:hover {
          border-color: ${theme[COLOR_ENUM.ERROR]}!important;
        }
      `;
    }
  }}
`;

const ChatContainer = styled.div`
  display: grid;
  overflow: scroll;
  max-height: 100%;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  padding-top: 40px;
  height: min-content !important;
`;

const CustomFieldContainer = styled.div`
  position: relative;
  width: 100%;
`;

const ButtonStyled = styled(ButtonElem)`
  padding: 0;
  height: 20px;
  width: 20px;
  position: absolute;
  right: 16px;
  bottom: 21px;
  cursor: pointer;
  img {
    cursor: pointer;

    height: 20px;
    width: 20px;
  }
`;

const Container = styled(ContentContainerElem)`
  box-sizing: border-box;
  justify-content: end;

  padding: 22px 0;
  padding-top: 0;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  ::after {
    content: '';
    display: block;
    width: 100%;
    height: 50px;
    /* background: linear-gradient(rgba(27, 25, 36, 1), rgba(27, 25, 36, 0)); */
    background: ${({ theme }) =>
      `linear-gradient(${theme[COLOR_ENUM.BACKGROUND_SECONDARY]}FF, ${
        theme[COLOR_ENUM.BACKGROUND_SECONDARY]
      }00)`};
    border-radius: ${SIZE_BORDER_RADIUS_DATA[
      SIZE_BORDER_RADIUS_ENUM.DEFAULT
    ]}px;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
  }
  height: 100%;
  max-height: 100%;
  /* @media screen and (width<1060px) {
    max-height: 500px;
    min-height: 300px;
  } */

  @media screen and (width<801px) {
    order: 2;
    max-height: 500px;
  }
`;
