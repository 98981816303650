import React from 'react';

import { Component } from './component';
import { maxLength, name, required } from '../../lib/validation/service';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { useMutation } from 'react-query';
import { action as fetch } from './action';
import { WEBCARD_ITEM_DATA_RAW } from '../../data/webcard/constant';
import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
} from '../webcard-item-create/constant';
import { convert } from '../webcard-item-create/convert';

export const Container: React.FC<{ data: WEBCARD_ITEM_DATA_RAW }> = ({
  data,
}) => {
  const action = useMutation((values: FORM_VALUE_INTER) =>
    fetch(values, data.id),
  );

  const config = {
    [FORM_VALUE_ENUM.NAME]: [required, name, maxLength(80)],
    [FORM_VALUE_ENUM.DESCRIPTION]: [required],
    [FORM_VALUE_ENUM.FILE]: [required],
  };

  const validate = (values: FormikValues) => validation(values, config);

  const initialValues = {
    [FORM_VALUE_ENUM.NAME]: data.name,
    [FORM_VALUE_ENUM.DESCRIPTION]: data.description,
    [FORM_VALUE_ENUM.FILE]: data.photo?.id,
  };

  const formik: FormikValues = useFormik({
    initialValues,
    validate,
    onSubmit: (values: FORM_VALUE_INTER) => {
      return action.mutate(convert(values));
    },
  });

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }

    if (action.isLoading) {
      return true;
    }
  };

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (action.isSuccess) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  const onSuccessUpload = (id: string, name: string) => {
    formik.setFieldValue(name, id);
    formik.handleSubmit();
  };
  return (
    <Component
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled}
      getFieldValue={getFieldValue}
      formik={formik}
      isError={isError()}
      isLoading={isLoading()}
      isSuccess={isSuccess()}
      errorMessage={getErrorMessage()}
      data={data}
      onSuccessUpload={onSuccessUpload}
    />
  );
};
