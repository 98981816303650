import { useEffect } from 'react';
import { useSocket } from '../../data/socket/provider';

import {
  MESSAGE_ITEM_DATA,
  MESSAGE_ITEM_DATA_RAW,
} from '../../data/message/constant';
import { BASE_URL } from '../../lib/http/constant';
import { useSelector } from '../../lib/store';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { STORE_INTER } from '../../data/auth/constant';
import { HttpRequest } from '../../lib/http';
import { convertMessage, convertMessageList } from '../../data/message/convert';

interface useRoomSocketProps {
  projectId: string;
  messageDateFilter: string;
  setChatMessage: React.Dispatch<
    React.SetStateAction<MESSAGE_ITEM_DATA[] | undefined>
  >;
}

const useRoomSocket = ({
  projectId,
  setChatMessage,
  messageDateFilter,
}: useRoomSocketProps) => {
  const { socket, initializeSocket, sendMessageModer, changeChatActive } =
    useSocket();

  const state: STORE_INTER = useSelector((s) => s[AUTH_MODULE_NAME]);

  useEffect(() => {
    if (projectId) {
      initializeSocket(BASE_URL as string, {
        auth: {
          token:
            state?.user?.token ||
            (
              HttpRequest.defaults.headers.common['Authorization'] as string
            )?.replace('Bearer ', ''),
          projectId,
          messageDateFilter,
        },
      });
    }
  }, [projectId, messageDateFilter]);

  useEffect(() => {
    if (socket) {
      console.log('if (socket) {');
      socket.on('sessionMessagesModer', (messages: MESSAGE_ITEM_DATA_RAW[]) => {
        console.log('sessionMessagesModer', messages);
        setChatMessage(convertMessageList(messages));
      });

      socket.on('chatModer', (message: MESSAGE_ITEM_DATA_RAW) => {
        console.log('chatModer', message);
        setChatMessage((previousMessages: MESSAGE_ITEM_DATA[] | undefined) => {
          if (previousMessages) {
            return [...previousMessages, convertMessage(message)];
          } else {
            return [convertMessage(message)];
          }
        });
      });

      socket.on('approveMessage', (message: MESSAGE_ITEM_DATA_RAW) => {
        console.log('approveMessage', message);
        setChatMessage((previousMessages: MESSAGE_ITEM_DATA[] | undefined) => {
          return previousMessages?.map((item) => {
            if (item.id === message.id) {
              return convertMessage(message);
            }
            return item;
          });
        });
      });

      socket.on('deleteMessageList', (idsToRemove: string[]) => {
        setChatMessage((previousMessages: MESSAGE_ITEM_DATA[] | undefined) => {
          return previousMessages?.filter(
            (message) => !idsToRemove?.includes(message.id),
          );
        });
      });

      socket.on('deleteMessage', (messageId: string) => {
        setChatMessage((previousMessages: MESSAGE_ITEM_DATA[] | undefined) => {
          return previousMessages?.filter((msg) => msg.id !== messageId);
        });
      });

      const handleBeforeUnload = () => {
        socket.disconnect();
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        handleBeforeUnload();
      };
    }
  }, [socket]);

  return {
    sendMessageModer,
    changeChatActive,
  };
};

export default useRoomSocket;
