export const MODULE_NAME = 'WEBINAR_ITEM_UPDATE_GENERAL_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  NAME = 'name',
  PROJECT = 'project',
  SPEAKER_NAME = 'speakerName',
  SPEAKER_IMG = 'speakerImg',
  LOGO = 'logo',
  TOPIC = 'topics',
  BONUS = 'bonuses',
  GUARANTES = 'guarantes',
  PRICE = 'price',
  PREVIEW = 'preview',
  VIDEO = 'video',
  YOUTUBE_LINK = 'youtubeLink',
  MODER_MESSAGE = 'moderMessage',
  PIXEL_ID = 'pixelId',
  CRM_LINK = 'crmLink',
  CRM_STATUS = 'crmStatus',
  CRM_PIPELINE = 'crmPipeline',
  CRM_FORM_NAME = 'crmFormName',
  CRM_EVENT_NAME = 'crmEventName',
  SMS_TEXT = 'smsText',
  VALUTE = 'valute',
  ANIMATION_ACTIVE = 'animationActive',
  YOUTUBE_LINK_PREVIEW = 'youtubeLinkPreview',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.NAME]?: string;
  [FORM_VALUE_ENUM.PROJECT]?: string;
  [FORM_VALUE_ENUM.SPEAKER_NAME]?: string;
  [FORM_VALUE_ENUM.SPEAKER_IMG]?: string;
  [FORM_VALUE_ENUM.PREVIEW]?: string;
  [FORM_VALUE_ENUM.VIDEO]?: string;
  [FORM_VALUE_ENUM.LOGO]?: string;
  [FORM_VALUE_ENUM.TOPIC]?: string[];
  [FORM_VALUE_ENUM.BONUS]?: string[];
  [FORM_VALUE_ENUM.GUARANTES]?: string[];
  [FORM_VALUE_ENUM.PRICE]?: number | string;
  [FORM_VALUE_ENUM.YOUTUBE_LINK]?: string;
  [FORM_VALUE_ENUM.YOUTUBE_LINK_PREVIEW]?: string;
  [FORM_VALUE_ENUM.MODER_MESSAGE]?: string;
  [FORM_VALUE_ENUM.PIXEL_ID]?: string;
  [FORM_VALUE_ENUM.CRM_LINK]?: string;
  [FORM_VALUE_ENUM.CRM_STATUS]?: string;
  [FORM_VALUE_ENUM.CRM_PIPELINE]?: string;
  [FORM_VALUE_ENUM.CRM_FORM_NAME]?: string;
  [FORM_VALUE_ENUM.CRM_EVENT_NAME]?: string;
  [FORM_VALUE_ENUM.SMS_TEXT]?: string;
  [FORM_VALUE_ENUM.VALUTE]?: string;
  [FORM_VALUE_ENUM.ANIMATION_ACTIVE]?: boolean;
}
export const API = {
  UPDATE: {
    TYPE: 'PATCH',
    URL: (webinarid: string) => `/webinar/${webinarid}`,
  },
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
