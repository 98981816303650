import { API } from './constant';
import { HttpRequest } from '../../lib/http';

export const action = (payload: string[]) => {
  return HttpRequest({
    method: API.TYPE,
    url: API.URL,
    data: payload,
  });
};
