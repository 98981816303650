import React from 'react';
import avatarIcon from '../../asset/svg/avatar.svg';
import avatarLightIcon from '../../asset/svg/avatar-light.svg';
import styled, { css } from 'styled-components';
import { COLOR_ENUM, LIGHT_COLOR_DATA } from '../../theme/color';

export const Elem: React.FC<{
  userAvatar?: any;
  size?: number;
  borderRadius?: boolean;
  iconSize?: number;
  className?: string;
}> = ({ userAvatar, size = 48, iconSize, className }) => {
  return (
    <Container size={size} className={className}>
      <Avatar src={userAvatar || avatarIcon} iconSize={iconSize} />
    </Container>
  );
};

const Avatar = styled.img<{
  iconSize?: number;
}>`
  ${({ iconSize }) => css`
    height: ${iconSize ? iconSize + 'px' : '100%'} !important;
    width: ${iconSize ? iconSize + 'px' : '100%'} !important;
  `};
  border-radius: 50%;
`;

const Container = styled.div<{
  size?: number;
}>`
  ${({ size }) => css`
    height: ${size}px !important;
    min-width: ${size}px !important;
    min-height: ${size}px !important;

    width: ${size}px !important;
  `};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
`;
