import React, { useEffect, useMemo, useState } from 'react';

import { Component } from './component';

import { MESSAGE_ITEM_DATA } from '../../data/message/constant';
import useRoomSocket from './useSocket';

export const Container: React.FC<{}> = ({}) => {
  const [chatMessage, setChatMessage] = useState<
    MESSAGE_ITEM_DATA[] | undefined
  >(undefined);
  const [projectId, setProject] = useState('');
  const [messageDateFilter, setMessageDateFilter] = useState(
    new Date().toISOString(),
  );
  const [messageTypeFilter, setMessageTypeFilter] = useState('ALL');
  const [filtredMessage, setFiltredMessage] = useState<
    MESSAGE_ITEM_DATA[] | undefined
  >(undefined);
  const [selectedMessages, setSelectedMessages] = useState<{
    messageIds: string[];
    viewerIds: string[];
  }>({
    messageIds: [],
    viewerIds: [],
  });

  const toggleMessageSelection = (id: string, viewerId?: string) => {
    setSelectedMessages((prevSelected) => {
      // Обновление messageIds
      const updatedMessageIds = prevSelected.messageIds.includes(id)
        ? prevSelected.messageIds.filter((messageId) => messageId !== id)
        : [...prevSelected.messageIds, id];

      // Обновление viewerIds, если viewerId существует
      const updatedViewerIds = viewerId
        ? prevSelected.viewerIds.includes(viewerId)
          ? prevSelected.viewerIds.filter((vId) => vId !== viewerId)
          : [...prevSelected.viewerIds, viewerId]
        : prevSelected.viewerIds; // Если viewerId нет, оставляем без изменений

      return {
        messageIds: updatedMessageIds,
        viewerIds: updatedViewerIds,
      };
    });
  };

  const { sendMessageModer } = useRoomSocket({
    projectId,
    messageDateFilter,
    setChatMessage,
  });

  const filterMessage = () => {
    return chatMessage?.filter((item) => {
      if (messageTypeFilter === 'MODERATION') {
        return item.moderCheck === false;
      }
      return item;
    });
  };

  useEffect(() => {
    setFiltredMessage(filterMessage());
  }, [chatMessage, messageTypeFilter]);

  useEffect(() => {
    setFiltredMessage(undefined);
  }, [projectId, messageDateFilter]);

  const resetSelectedList = () => {
    setSelectedMessages({ messageIds: [], viewerIds: [] });
  };

  return (
    <Component
      projectId={projectId}
      chatMessage={filtredMessage}
      sendMessage={sendMessageModer}
      setChatMessage={setChatMessage}
      messageDateFilter={messageDateFilter}
      setMessageDateFilter={setMessageDateFilter}
      messageTypeFilter={messageTypeFilter}
      setMessageTypeFilter={setMessageTypeFilter}
      toggleMessageSelection={toggleMessageSelection}
      selectedMessages={selectedMessages}
      resetSelectedList={resetSelectedList}
      setProject={setProject}
    />
  );
};
