import React from 'react';
import { useMutation } from 'react-query';
import { LoaderElem } from '../../common/loader';
import { ACTION_ERROR_INTER } from './constant';
import { AlertActionElem } from '../../common/alert-action';
import { ButtonElem } from '../../common/button';
import { action } from './action';
import { ReactComponent as DeleteIcon } from '../../asset/svg/button/delete.svg';
import { LoaderIconElem } from '../../common/loader-icon';
import { DeleteIconElem } from '../../common/delete-icon';

export const Container: React.FC<{
  projectId: string;
  selectedList: string[];
  resetSelectedList: Function;
}> = ({ projectId, selectedList, resetSelectedList }) => {
  const onSuccess = () => {
    resetSelectedList();
  };
  const actionMutate = useMutation(
    () => action({ projectId, messageList: selectedList }),
    {
      onSuccess,
    },
  );

  const isLoading = () => {
    if (actionMutate.isLoading) {
      return true;
    }
  };

  const actionHandleClick = (e: any) => {
    actionMutate.mutate();
  };

  const isError = () => {
    if (actionMutate.isError && !actionMutate.isLoading && getErrorMessage())
      return true;
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = actionMutate.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };
  return (
    <>
      {isLoading() && <LoaderElem />}
      <DeleteIconElem
        onClick={actionHandleClick}
        isLoading={isLoading()}
        titleTid="COMMON.MODAL.TITLE.SESSION"
      >
        <ButtonElem
          color="error"
          tid="SESSION.MODERATION.MESSAGE.DELETE"
          componentRight={
            isLoading() ? <LoaderIconElem color="error" /> : <DeleteIcon />
          }
          disabled={isLoading() || selectedList.length === 0}
        />
      </DeleteIconElem>
      {isError() && <AlertActionElem tid={getErrorMessage()} />}
    </>
  );
};
