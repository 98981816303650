import React from 'react';
import { GridElem } from '../../common/grid';
import styled from 'styled-components';
import { SESSION_ITEM_DATA } from '../../data/session/constant';
import { MESSAGE_ITEM_DATA } from '../../data/message/constant';
import { SessionItemModerationChatStartContainer } from '../session-item-moderation-chat-start';
import { ContentContainerElem } from '../../common/content-container';
import { FieldDataTimeInputElem } from '../../common/field-datetime-input';
import { TabActionElem } from '../../common/tab-action';
import { TAB_LIST } from './constant';
import { ModerationChatContainer } from '../moderation-chat';
import { ModerationMessageDeleteContainer } from '../moderation-message-delete';
import { ModerationViewerBanContainer } from '../moderation-viewer-ban';
import { ModerationViewerMuteContainer } from '../moderation-viewer-mute';
import { convertProjectListToSelect } from '../../data/project/convert';
import { getProjectList } from '../../data/project/action';
import { SelectPreloadContainer } from '../select-preload';
import { COLOR_ENUM } from '../../theme/color';

export const Component: React.FC<{
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  data?: SESSION_ITEM_DATA;
  projectId: string;
  chatMessage?: MESSAGE_ITEM_DATA[];
  sendMessage: Function;
  setChatMessage: Function;

  messageTypeFilter: string;
  setMessageTypeFilter: Function;
  messageDateFilter: string;
  setMessageDateFilter: Function;
  toggleMessageSelection: Function;
  selectedMessages: { messageIds: string[]; viewerIds: string[] };
  resetSelectedList: Function;
  setProject: Function;
}> = ({
  projectId,
  chatMessage,
  sendMessage,
  setChatMessage,

  messageTypeFilter,
  setMessageTypeFilter,
  messageDateFilter,
  setMessageDateFilter,
  toggleMessageSelection,
  selectedMessages,
  resetSelectedList,
  setProject,
}) => {
  const onDateChange = (e: any) => {
    setMessageDateFilter(e.target.value);
  };
  const onChange = (name: string, values: any) => {
    setProject(values.value);
  };
  return (
    <Container>
      <ModerationChatContainer
        chatMessage={chatMessage}
        sendMessage={sendMessage}
        setChatMessage={setChatMessage}
        toggleMessageSelection={toggleMessageSelection}
        selectedMessages={selectedMessages}
        projectId={projectId}
      />
      <ActionContainer spacing={4}>
        <SelectCustom
          name={'project'}
          action={getProjectList}
          convert={convertProjectListToSelect}
          onChange={onChange}
          noAlertContainer
          placeholder="WEBINAR.LIST.FILTER.SELECT.PLACEHOLDER"
        />
        <FieldDataTimeInput
          showTime={false}
          value={messageDateFilter}
          onChange={onDateChange}
          format="YYYY-MM-DD"
        />
        <ContentContainer>
          <TabAction
            tabList={TAB_LIST}
            onChange={setMessageTypeFilter}
            value={messageTypeFilter}
          />
        </ContentContainer>
        <ContentContainer>
          <TrippleContainer>
            <ModerationMessageDeleteContainer
              selectedList={selectedMessages.messageIds}
              resetSelectedList={resetSelectedList}
              projectId={projectId}
            />
            <DoubleContainer>
              <ModerationViewerBanContainer
                selectedList={selectedMessages.viewerIds}
                resetSelectedList={resetSelectedList}
              />
              <ModerationViewerMuteContainer
                selectedList={selectedMessages.viewerIds}
                resetSelectedList={resetSelectedList}
              />
            </DoubleContainer>
          </TrippleContainer>
        </ContentContainer>
      </ActionContainer>
    </Container>
  );
};

const DoubleContainer = styled(GridElem)`
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  @media screen and (width<450px) {
    grid-template-columns: 1fr;
  }
`;

const FieldDataTimeInput = styled(FieldDataTimeInputElem)`
  .ant-picker {
    background: ${({ theme }) =>
      theme[COLOR_ENUM.DEFAULT] === '#1B1924'
        ? theme[COLOR_ENUM.INPUT_BACKGROUND]
        : theme[COLOR_ENUM.INPUT]};
  }
`;

const SelectCustom = styled(SelectPreloadContainer)`
  .Select__control {
    background: ${({ theme }) =>
      theme[COLOR_ENUM.DEFAULT] === '#1B1924'
        ? theme[COLOR_ENUM.INPUT_BACKGROUND]
        : theme[COLOR_ENUM.INPUT]};
  }
`;

const TabAction = styled(TabActionElem)`
  ::after {
    display: none;
  }

  width: 100%;
`;

const ContentContainer = styled(ContentContainerElem)`
  padding: 24px;
`;
const Container = styled(GridElem)`
  @media screen and (width<800px) {
    display: flex;
    flex-direction: column;
  }
  overflow: auto;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  height: 100%;
  max-height: 100%;
`;

const TrippleContainer = styled(GridElem)`
  grid-template-columns: 1fr 2fr;

  gap: 12px;
  > {
    height: 100%;
    max-height: 100%;
  }
  @media screen and (width<600px) {
    grid-template-columns: 1fr;
  }
`;

const ActionContainer = styled(GridElem)``;
