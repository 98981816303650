import React from 'react';
import { useMutation } from 'react-query';
import { ACTION_ERROR_INTER } from './constant';
import { AlertActionElem } from '../../common/alert-action';
import { ButtonElem } from '../../common/button';
import { action } from './action';
import { ReactComponent as BanIcon } from '../../asset/svg/session/moderation/ban.svg';
import { LoaderIconElem } from '../../common/loader-icon';
import { ModalConfirmElem } from '../../common/modal-confirm';
import styled from 'styled-components';
import { COLOR_ENUM } from '../../theme/color';

export const Container: React.FC<{
  selectedList: string[];
  resetSelectedList: Function;
}> = ({ selectedList, resetSelectedList }) => {
  const onSuccess = () => {
    resetSelectedList();
  };
  const actionMutate = useMutation(() => action(selectedList), {
    onSuccess,
  });

  const isLoading = () => {
    if (actionMutate.isLoading) {
      return true;
    }
  };

  const actionHandleClick = (e: any) => {
    actionMutate.mutate();
  };

  const isError = () => {
    if (actionMutate.isError && !actionMutate.isLoading && getErrorMessage())
      return true;
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = actionMutate.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };
  return (
    <>
      <ModalConfirmElem action={actionHandleClick} type="confirm">
        <ButtonStyled
          color="backgroundThird"
          tid="SESSION.MODERATION.MESSAGE.BAN"
          componentRight={
            isLoading() ? <LoaderIconElem color="textDefault" /> : <BanIcon />
          }
          disabled={isLoading() || selectedList.length === 0}
        />
      </ModalConfirmElem>
      {isError() && <AlertActionElem tid={getErrorMessage()} />}
    </>
  );
};

const ButtonStyled = styled(ButtonElem)`
  svg {
    path {
      fill: ${({ theme }) => theme[COLOR_ENUM.TEXT_DEFAULT]};
    }
  }
`;
