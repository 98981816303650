import { IonSkeletonText } from '@ionic/react';
import React from 'react';
import styled from 'styled-components';
import { GridElem } from '../../../common/grid';
import { Container, HeadContainer } from './message-item-list.elem';

export const MessageSkeletonElem: React.FC = ({}) => {
  return (
    <>
      {[1, 2, 3, 4].map((item) => (
        <Container>
          <AvatarElem className="avatar" animated />
          <GridElem spacing={3}>
            <HeadContainer>
              <TextElem style={{ width: '90px' }} />
              <TextElem style={{ width: '32px' }} />
            </HeadContainer>

            <GridElem spacing={0.25}>
              <TextElem />
              <TextElem />
            </GridElem>
          </GridElem>
        </Container>
      ))}
    </>
  );
};

const AvatarElem = styled(IonSkeletonText)`
  width: 20px;
  height: 20px;
  border-radius: 100%;
`;

const TextElem = styled(IonSkeletonText)`
  height: 13px;
  width: 100%;
  border-radius: 6.5px;
`;
