export const MODULE_NAME = 'SESSION_ITEM_CREATE_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  NAME = 'name',
  DATE = 'date',
  ONLINE_MIN = 'onlineUserMin',
  ONLINE_MAX = 'onlineUserMax',
  TIMEZONE = 'timeZone',
  AUTO = 'auto',
  PEAK_START_TIME = 'peakStartTime',
  PEAK_END_TIME = 'peakEndTime',
  FORCE_START = 'forceStart',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.DATE]: Date | string;
  [FORM_VALUE_ENUM.NAME]: string;
  [FORM_VALUE_ENUM.TIMEZONE]: string;
  [FORM_VALUE_ENUM.ONLINE_MAX]: number | string;
  [FORM_VALUE_ENUM.ONLINE_MIN]: number | string;
  [FORM_VALUE_ENUM.AUTO]: boolean;
  [FORM_VALUE_ENUM.FORCE_START]: boolean;
  [FORM_VALUE_ENUM.PEAK_END_TIME]: string;
  [FORM_VALUE_ENUM.PEAK_START_TIME]: string;
}

export const API = {
  TYPE: 'POST',
  URL: (webinarId: string) => `/session/create/${webinarId}`,
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
