export const MODULE_NAME = 'WEBCARD_ITEM_UPDATE_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  TYPE: 'PATCH',
  URL: (id: string) => `/webcard/${id}`,
};
