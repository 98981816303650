import { TextElem } from '../../../common/text';
import { FlexElem } from '../../../common/flex';
import { ReactComponent as ArrowIcon } from '../../../asset/svg/session/arrow.svg';

export const ProgressTitleElem: React.FC<{ center?: boolean }> = ({
  center,
}) => {
  return (
    <FlexElem
      spacing={1.5}
      style={{
        justifyContent: center ? 'center' : 'start',
      }}
    >
      <TextElem
        type="semi-bold"
        tid="SESSION.VIEWER.RESERVATION.MODAL.TITLE_2"
        color="textSecondary"
        size="medium"
      />
      <ArrowIcon />
    </FlexElem>
  );
};
